import i18n from "../i18n";

export enum CourseTrainingType {
  Instant = 1, //Tests
  Mandatory = 2, //challenges
  Regular = 3, //scheduled training
}

export interface ICourseTrainingType {
  name: string;
  color: string;
}

type CourseTrainingTypeList = {
  [key in CourseTrainingType]: ICourseTrainingType;
};

export const COURSE_TRAINING_TYPE_LIST: CourseTrainingTypeList = {
  [CourseTrainingType.Instant]: {
    name: i18n.t("courseTrainingType.instant"),
    color: "#924915",
  },
  [CourseTrainingType.Mandatory]: {
    name: i18n.t("courseTrainingType.mandatory"),
    color: "#f47923",
  },
  [CourseTrainingType.Regular]: {
    name: i18n.t("courseTrainingType.regular"),
    color: "#fbc9a7",
  },
};
