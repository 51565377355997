import { Stack, Typography } from "@mui/material";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

interface ArchProps {
  primaryText: string;
  secondaryText?: string;
  color: "error" | "success" | "default";
  completion: number;
}

const ArchDiagram: React.FunctionComponent<ArchProps> = ({
  color,
  primaryText,
  secondaryText,
  completion,
}) => {
  const colorTextMap = {
    error: "#e73219",
    success: "#1da152",
    default: "#78716c",
  };

  const colorPrimaryMap = {
    error: "#d87c6e",
    success: "#73b38b",
    default: "#78716c",
  };

  const colorSecondaryMap = {
    error: "#c9c6c4",
    success: "#8ed0a8",
    default: "#c9c6c4",
  };

  const colorText = colorTextMap[color];
  const colorPrimary = colorPrimaryMap[color];
  const colorSecondary = colorSecondaryMap[color];

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        height: 200,
      }}
    >
      <Stack alignItems="center">
        <Typography
          variant="h4"
          style={{
            fontWeight: 700,
            color: colorText,
          }}
        >
          {primaryText}
        </Typography>
        {secondaryText && <Typography>{secondaryText}</Typography>}
      </Stack>
      <div
        style={{
          width: 200,
          height: 200,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10,
        }}
      >
        <Doughnut
          options={{
            responsive: true,
            cutout: 85,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          }}
          data={{
            datasets: [
              {
                data: [completion, 100 - completion],
                backgroundColor: [colorPrimary, "transparent"],
                hoverBorderColor: "transparent",
                borderWidth: 0,
                borderRadius: 10,
              },
            ],
          }}
        />
      </div>
      <div
        style={{
          width: 200,
          height: 200,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
      >
        <Doughnut
          options={{
            responsive: true,
            cutout: 85,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          }}
          data={{
            datasets: [
              {
                data: [85, 15],
                backgroundColor: [colorSecondary, "transparent"],
                hoverBorderColor: "transparent",
                borderWidth: 0,
                borderRadius: 10,
              },
            ],
          }}
        />
      </div>
    </Stack>
  );
};

export default ArchDiagram;
