import { useEffect, useState } from "react";
import { authentication, getContext } from "@microsoft/teams-js";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { IChannel, IChannelsResponse } from "../types/channel";
import { track } from "@amplitude/analytics-browser";

import { app } from "@microsoft/teams-js";

import AnswerCards from "../components/AnswerCards";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";

export default function MyAnswers() {
  const { t } = useTranslation();

  const [isChannelsLoading, setIsChannelsLoading] = useState(true);
  const [currentChannel, setCurrentChannel] = useState<string>();
  const [channels, setChannels] = useState<IChannel[]>([]);

  const [isPrivate, setIsPrivate] = useState(true);

  useEffect(() => {
    app.getContext().then((context) => {
      track(
        context.channel
          ? `OpenMyAnswersTab-${context.channel.id}`
          : "OpenMyAnswersTab"
      );
      if (context.channel) {
        setIsPrivate(true);
        setCurrentChannel(context.channel.id);
      } else {
        setIsPrivate(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!isPrivate) {
      authentication
        .getAuthToken()
        .then((token) => {
          getContext((context) => {
            axios
              .get<IChannelsResponse>("channels/getUserChannels", {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                params: { userObjectId: context.userObjectId },
              })
              .then((res) => {
                setChannels(res.data.channels);
                setIsChannelsLoading(false);
              })
              .catch((err) => {
                setIsChannelsLoading(false);
                console.log("err:", err);
              });
          });
        })
        .catch((err) => {
          setIsChannelsLoading(false);
          console.log("err:", err);
        });
    }
  }, [isPrivate]);

  useEffect(() => {
    if (channels[0]) {
      setCurrentChannel(channels[0].teamsChannelId);
    } else {
      setCurrentChannel(undefined);
    }
  }, [channels]);

  useEffect(() => {
    app.notifyAppLoaded();
  }, []);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        spacing={2}
        style={{ paddingBottom: 16 }}
      >
        {isPrivate ? (
          <Grid item>
            <Typography variant="h2" component="h1">
              {t("texts.myAnswers")}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Typography variant="h2" component="h1">
                {t("texts.myAnswersInTeam")}
              </Typography>
            </Grid>
            <Grid item>
              {channels.length > 0 && (
                <Select
                  value={currentChannel || ""}
                  onChange={(event) => {
                    setCurrentChannel(event.target.value as string);
                  }}
                  variant="filled"
                  disableUnderline
                >
                  {channels.map((channel) => (
                    <MenuItem key={channel.id} value={channel.teamsChannelId}>
                      {channel.teamName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
          </>
        )}
      </Grid>

      {currentChannel ? <AnswerCards channelId={currentChannel} /> : null}
    </div>
  );
}
