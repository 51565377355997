import i18n from "../i18n";

export enum CourseStatus {
  Visible = 1,
  Finished = 3,
}

export interface ICourseStatus {
  name: string;
  color: string;
}

type CourseStatusList = {
  [key in CourseStatus]: ICourseStatus;
};

export const COURSE_STATUS_LIST: CourseStatusList = {
  [CourseStatus.Visible]: {
    name: i18n.t("courseStatus.running"),
    color: "#2e7d32",
  },
  [CourseStatus.Finished]: {
    name: i18n.t("courseStatus.finished"),
    color: "#1c1917",
  },
};
