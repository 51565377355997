import {
  HashRouter as Router,
  Route,
  HashRouter,
  Routes,
  useLocation,
} from "react-router-dom";

import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { app, authentication } from "@microsoft/teams-js";

import { init, setUserId } from "@amplitude/analytics-browser";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import TeamsLeaderboard from "./pages/TeamsLeaderboard";
import MyAnswers from "./pages/MyAnswers";
import Challenges from "./pages/Challenges";
import Tests from "./pages/Tests";
import Home from "./pages/Home";
import Admin from "./pages/Admin";

import MyCourses from "./pages/MyCourses";
import MyCourse from "./pages/MyCourses/Course";

import { ProviderState, Providers } from "@microsoft/mgt-element";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";

import { TeamsFxContext } from "./Context";

import { ThemeProvider } from "@material-ui/core";
import baseTheme from "./themes/base";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";

const scopes = ["User.Read", "User.ReadBasic.All"];

const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_APPLICATION_INSIGTHS_CONNECTION_STRING,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function App() {
  const { i18n } = useTranslation();

  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: "/auth-start.html",
      clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    });

  const initialize = useCallback(async () => {
    if (loading || !teamsUserCredential) {
      return;
    }

    await app.initialize();

    await init(process.env.REACT_APP_AMPLITUDE_API_KEY as string).promise;

    const provider = new TeamsFxProvider(teamsUserCredential, scopes);
    Providers.globalProvider = provider;

    try {
      // await teamsUserCredential.getToken(scopes);
      await authentication.getAuthToken();
      Providers.globalProvider.setState(ProviderState.SignedIn);
    } catch (error) {
      console.log(error);
      await teamsUserCredential.login(scopes).catch((error) => {
        console.error(error);
      });
      Providers.globalProvider.setState(ProviderState.SignedIn);
    }

    app.getContext().then((context) => {
      setUserId(context.user?.id);

      const language = context.app.locale.split("-")[0];
      i18n.changeLanguage(language);
      moment.locale(context.app.locale);
      app.notifySuccess();
    });
  }, [i18n, loading, teamsUserCredential]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <TeamsFxContext.Provider value={{ teamsUserCredential }}>
      <ThemeProvider theme={baseTheme}>
        {app.isInitialized() && (
          <HashRouter>
            <Routes>
              <Route path="/leaderboard" element={<TeamsLeaderboard />} />
              <Route path="/answers" element={<MyAnswers />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/home" element={<Home />} />
              <Route path="/courses" element={<MyCourses />} />
              <Route
                path="/courses/:courseDistributionId/:courseTrainingType"
                element={<MyCourse />}
              />
            </Routes>
          </HashRouter>
        )}
      </ThemeProvider>
    </TeamsFxContext.Provider>
  );
}
