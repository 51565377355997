import { useCallback, useEffect, useState } from "react";

import { authentication } from "@microsoft/teams-js";

import { useTranslation } from "react-i18next";
import axios from "axios";

import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  TypographyProps,
} from "@material-ui/core";

import {
  ILessonFeedbackType,
  LESSON_FEEDBACK_TYPE_LIST,
  LessonFeedbackType,
} from "../constants/lessonFeedbackTypes";
import _ from "lodash";
import QuestionCircle from "./icons/QuestionCircle";

import FsLightbox from "fslightbox-react";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { ILesson } from "../types/course";
import { CourseTrainingType } from "../constants/courseTrainingType";
import { Stack } from "@mui/material";
import BooksMulticolor from "./icons/BooksMulticolor";

interface ILessonCardProps {
  courseTrainingType?: CourseTrainingType;
  lesson: ILesson;
  callback?: () => void;
  inactive?: boolean;
}

const LessonCard: React.FC<ILessonCardProps> = ({
  courseTrainingType,
  lesson,
  callback,
  inactive,
}) => {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState(lesson.answerGivenId);
  const [localCopy, setLocalCopy] = useState<ILesson>(lesson);
  const [isLoading, setIsLoading] = useState(false);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
  const [toggler, setToggler] = useState(false);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const onFeedback = useCallback(
    async (badgeTypeId: LessonFeedbackType) => {
      setIsFeedbackLoading(true);
      authentication
        .getAuthToken()
        .then((token) => {
          axios
            .post<ILesson>(
              `/Courses/SaveLessonFeedback`,
              {
                quizId: lesson.quizId,
                badgeTypeId: parseInt(badgeTypeId.toString()),
                courseTrainingType: courseTrainingType,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              setLocalCopy(res.data);
            })
            .catch((err) => {
              console.log("err:", err);
            })
            .finally(() => {
              setIsFeedbackLoading(false);
            });
        })
        .catch((err) => {
          setIsFeedbackLoading(false);
          console.log("err:", err);
        });
    },
    [lesson.quizId]
  );

  const onSubmit = useCallback(async () => {
    if (!selectedValue && localCopy.answers.length > 0) {
      return;
    }

    setIsLoading(true);

    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .post<ILesson>(
            `/Courses/SaveLessonAnswer`,
            {
              quizId: lesson.quizId,
              answerId: selectedValue,
              courseTrainingType: courseTrainingType,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("res:", res.data);
            setLocalCopy((current) => ({ ...current, ...res.data }));
            callback && callback();
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err:", err);
      });
  }, [lesson.quizId, selectedValue, callback, localCopy.answers]);

  useEffect(() => {
    setLocalCopy(lesson);
  }, [lesson]);

  return (
    <Paper
      style={
        lesson.answerGivenId || localCopy.isCorrect !== undefined
          ? {
              backgroundColor: "#FFFCFA",
              border: "1px solid #8D8A88",
            }
          : undefined
      }
      elevation={
        lesson.answerGivenId || localCopy.isCorrect !== undefined ? 0 : 1
      }
    >
      <Grid container direction="column">
        {localCopy.questionJPG && (
          <Grid item container>
            <Grid item>
              <Card
                style={{ maxWidth: 345, borderRadius: 0, border: "none" }}
                component="div"
                elevation={0}
              >
                <CardActionArea
                  onClick={() => {
                    if (localCopy.videoUrl) {
                      window.open(localCopy.videoUrl, "_blank");
                    } else {
                      setToggler((e) => !e);
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    style={{
                      maxWidth: 345,
                      maxHeight: 160,
                      objectFit: "contain",
                    }}
                    image={localCopy.questionJPG}
                    alt="uploaded image"
                  />
                </CardActionArea>
              </Card>
            </Grid>
            <FsLightbox
              toggler={toggler}
              sources={[localCopy.questionJPG]}
              showThumbsOnMount={true}
              type="image"
            />
          </Grid>
        )}
        <Grid item>
          <StyledTypography
            answered={
              Boolean(localCopy.answerGivenId) ||
              localCopy.isCorrect !== undefined
            }
          >
            {/* @ts-ignore */}
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {localCopy.question}
            </ReactMarkdown>
          </StyledTypography>
        </Grid>
        <Grid item>
          <FormControl>
            <RadioGroup
              defaultValue={lesson.answerGivenId}
              value={selectedValue || 0}
              onChange={(event) => {
                setSelectedValue(parseInt(event.target.value));
              }}
            >
              {localCopy.answers.map((answer) => (
                <Stack direction="row" spacing={1} alignItems="center">
                  <FormControlLabel
                    key={answer.id}
                    value={answer.id}
                    disabled={Boolean(localCopy.answerGivenId) || inactive}
                    control={<Radio color="primary" />}
                    style={
                      localCopy.answerGivenId &&
                      !answer.isCorrect &&
                      localCopy.answerGivenId !== answer.id
                        ? { opacity: 0.6 }
                        : undefined
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={
                          localCopy.answerGivenId === answer.id &&
                          !answer.isCorrect
                            ? { color: "red" }
                            : answer.isCorrect
                            ? { color: "green" }
                            : undefined
                        }
                      >
                        {answer.text}
                      </Typography>
                    }
                  />
                  {localCopy.answerGivenId &&
                  answer.isCorrect &&
                  localCopy.description ? (
                    <Tooltip
                      arrow
                      style={{
                        cursor: "help",
                      }}
                      title={
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: 14,
                          }}
                        >
                          {/* @ts-ignore */}
                          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                            {localCopy.description}
                          </ReactMarkdown>
                        </Typography>
                      }
                    >
                      <span
                        style={{
                          height: 21,
                        }}
                      >
                        <QuestionCircle width={21} height={21} />
                      </span>
                    </Tooltip>
                  ) : null}
                </Stack>
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        {localCopy.contentUrl && (
          <Grid
            item
            style={{
              marginBottom: 16,
            }}
          >
            <Link href={localCopy.contentUrl} target="_blank" color="inherit">
              <Button
                startIcon={<BooksMulticolor width={22} height={22} />}
                variant="outlined"
                style={{
                  height: 28,
                  fontSize: 12,
                  fontWeight: 400,
                }}
                color="inherit"
              >
                {t("lessonCard.learnMore")}
              </Button>
            </Link>
          </Grid>
        )}
        {!localCopy.answerGivenId && localCopy.isCorrect === undefined ? (
          <Grid item>
            <Button
              variant="contained"
              disabled={
                isLoading ||
                (!selectedValue && localCopy.answers.length > 0) ||
                inactive
              }
              onClick={onSubmit}
            >
              {t("buttons.submit")}
            </Button>
          </Grid>
        ) : localCopy.feedback ? (
          <Grid item>
            <StyledTypography
              variant="caption"
              answered={
                Boolean(localCopy.answerGivenId) ||
                localCopy.isCorrect !== undefined
              }
            >
              {t("testCard.questionFeedback", {
                emoji: LESSON_FEEDBACK_TYPE_LIST[localCopy.feedback]?.emoji,
              })}
            </StyledTypography>
          </Grid>
        ) : (
          [
            <Grid key="text" item style={{ paddingTop: 12 }}>
              <StyledTypography
                variant="caption"
                answered={
                  Boolean(localCopy.answerGivenId) ||
                  localCopy.isCorrect !== undefined
                }
              >
                {t("testCard.rateLesson")}
              </StyledTypography>
            </Grid>,
            <Grid key="actions" container item spacing={2}>
              {_.map(
                LESSON_FEEDBACK_TYPE_LIST,
                (
                  feedbackType: ILessonFeedbackType,
                  key: LessonFeedbackType
                ) => (
                  <Grid key={key} item>
                    <Button
                      variant="contained"
                      onClick={() => onFeedback(key)}
                      disabled={isFeedbackLoading}
                    >
                      {feedbackType.emoji}
                    </Button>
                  </Grid>
                )
              )}
            </Grid>,
          ]
        )}
      </Grid>
    </Paper>
  );
};

const StyledTypography = (props: TypographyProps & { answered?: boolean }) => (
  <Typography
    {...props}
    style={
      props.answered
        ? {
            opacity: 0.6,
          }
        : undefined
    }
  >
    {props.children}
  </Typography>
);

export default LessonCard;
