import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,

  rotate = 0,
  strokeWidth = 4,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <rect
        x="18"
        y="24"
        width="14"
        height="44"
        rx="1"
        fill="#219653"
        stroke="#219653"
        strokeLinejoin="round"
      />
      <path
        d="M18 30.2842L32 30.2842"
        stroke="#F2C94C"
        strokeLinejoin="round"
      />
      <path d="M18 61.7126H32" stroke="#F2C94C" strokeLinejoin="round" />
      <rect
        x="32"
        y="12"
        width="14"
        height="56"
        rx="1"
        fill="#F2994A"
        stroke="#F2994A"
        strokeLinejoin="round"
      />
      <path d="M32 19.998L46 19.998" stroke="#EB5757" strokeLinejoin="round" />
      <path d="M32 59.998H46" stroke="#EB5757" strokeLinejoin="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.1893 34.1829C50.5778 34.6107 52.0601 34.7219 53.5251 34.4874L55.4137 34.1851C56.8786 33.9506 58.2523 33.3823 59.4378 32.5424L64.7193 65.5384C63.3309 65.1106 61.8485 64.9994 60.3836 65.2339L58.4949 65.5362C57.03 65.7707 55.6564 66.339 54.4709 67.1788L49.1893 34.1829Z"
        fill="#F2F2F2"
      />
      <path
        d="M49 33L54.6603 68.3625M59.2484 31.3596L64.9088 66.722"
        stroke="#F2994A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
